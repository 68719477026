import React, { useContext, useEffect } from 'react';
import { Button } from '@honehealth/components';
import mixpanel from 'mixpanel-browser';
import NavBar from '../components/Navbar';

import { myContext } from '../components/utils/ContextProvider';

export default function NoState() {
  mixpanel.init(process.env.MIXPANEL_KEY);
  const globalContext = useContext(myContext);

  useEffect(() => {
    mixpanel.track('Got to No State Page', {
      'Selected State': globalContext.selectedState,
    });
  }, []);
  return (
    <>
      <NavBar />
      <div className="min-h-screen flex items-center justify-center">
        <title>Not Available</title>
        <div className="bg-softWhite w-5/6 p-4 lg:p-8 rounded shadow-2xl lg:w-1/3">
          <div className="space-y-3 p-2 pt-4">
            <p className="text-red-600 text-center">Gah. We’re sorry!</p>
            <h1 className="text-2xl sm:text-3xl font-bold mb-4 text-black text-center">
              We’re not available in {globalContext.selectedState}. Yet!
            </h1>
            <h4 className="text-center text-black text-lg">
              Want to get started today? We can still help.
            </h4>
          </div>
          <div className="flex pt-3">
            <Button className="w-full transition duration-300 block h-16">
              <a href="https://honehealth.circle.so/home">JOIN OUR COMMUNITY</a>
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
